import { gql } from "@apollo/client";

export const TOTAL_STATISTIC = gql`
    query DashboardTotalStatistic($merchantId:String, $paymentMethod:String){
        dashboardTotalStatistic(merchantId:$merchantId, paymentMethod:$paymentMethod){
            merchant,
            payIn,
            payOut,
            settlement
        }
    }
`;

export const CHART_STATISTIC = gql`
    query DashboardStatistic($merchantId:String, $paymentMethod:String){
        dashboardStatistic(merchantId:$merchantId, paymentMethod:$paymentMethod){
            payIn{
                amount,
                date,
                count,
                status
            },
            payOut{
                amount,
                date,
                count,
                status
            },
            settlement{
                amount,
                date,
                count,
                status
            }
        }
    }
`;