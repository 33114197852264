import { Button, Divider } from "antd";
import { useEffect, useState } from "react";
import { SET_LANGUAGE, useLayout } from "../../../hooks/layout/LayoutContext";
import './LanguageSwitcher.less';

const LanguageSwitcher = () => {
    const { layoutDispatch } = useLayout();
    const [currentLocale, setCurrentLocale] = useState<string>('');

    useEffect(() => {
        const tempLocale = localStorage.getItem('ifal') || 'en';
        setCurrentLocale(tempLocale);
        setSelectedBtn(tempLocale);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLocale = (loc: string) => {
        localStorage.setItem('ifal', loc);
        layoutDispatch({ type: SET_LANGUAGE, payload: loc });
        resetSelectedBtn(loc);
    }

    const resetSelectedBtn = (lang: string): void => {
        let elements: HTMLCollectionOf<Element> = document.getElementsByClassName("btn-lang");
        for (const obj of elements) obj.classList.remove('active');
        setSelectedBtn(lang);
    }

    const setSelectedBtn = (lang: string): void => {
        document.getElementById("btn-" + lang)?.classList.add('active');
    }

    return (
        <div className="language-switcher-container">
            <Button size="small" type="link" id="btn-en" className={"btn-lang " + (currentLocale === 'en' ? "active " : "")} onClick={() => setLocale('en')}>
                EN
            </Button>
            <Divider className="language-switcher-divider" type="vertical" />
            <Button size="small" type="link" id="btn-zh-CN" className={"btn-lang " + (currentLocale === 'zh-CN' ? "active " : "")} onClick={() => setLocale('zh-CN')}>
                中文
            </Button>
        </div>
    )
}

export default LanguageSwitcher;