import { Column } from '@ant-design/charts';
import { Card, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { formatNumber } from '../../shared/helpers/number.helper';

interface Props {
    title: string;
    data: any;
    index: any;
    xFieldName: string;
    yFieldName: string;
    loading: boolean;
}

const ColumnChartView = ({ title, data, xFieldName, yFieldName, loading }: Props) => {
    const intl = useIntl();
    const colorGroup = ['#95de64', '#ff9c6e', '#d3f261', '#85a5ff', '#ff7875', '#69c0ff', '#ff85c0', '#ffc069', '#5cdbd3', '#fff566', '#b37feb'];

    const shuffle = (a: any) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    const config: any = {
        data,
        loading,
        isGroup: true,
        isStack: true,
        groupField: 'date',
        color: shuffle(colorGroup),
        xField: xFieldName,
        yField: yFieldName,
        seriesField: 'status',
        legend: {
            position: 'top-left',
            itemName: false,
            itemValue: {
                formatter: (text: any, item: any) => intl.formatMessage({ id: text })
            }
        },
        tooltip: {
            formatter: (item: any) => ({
                name: `${intl.formatMessage({ id: item.status })}(${item.count})`,
                value: formatNumber(intl, item.amount),
            })
        },
    };


    return (
        <>
            <Card className="text-center chartbox" >
                <Typography.Title level={4} underline>{title}</Typography.Title>
                <Column {...config} />
            </Card>
        </>
    );
};
export default ColumnChartView;